const stacks = [
	'JavaScript (ES6+)',
	'HTML',
	'Pug',
	'SASS',
	'CSS3',
	'Java',
	'Python',
	'C++',
	'TypeScript',
	'MATLAB',
	'React.js',
	'Redux',
	'Node.js',
	'Express',
	'Django',
	'Gatsby.js',
	'Serverless',
	'Hive',
	'MUI',
	'styled components',
	'Axios',
	'recharts',
	'FusionCharts',
	'pandas',
	'Numpy',
	'Matplotlib',
	'MongoDB',
	'headless CMS',
	'MySQL',
	'GraphQL',
	'Git',
	'Postman',
	'Z shell',
	'Docker',
	'Netlify',
	'Render',
	'Heroku',
	'AWS',
	'Google Cloud Platform',
	'VS Code',
	'PyCharm',
	'IntelliJ',
	'XCode',
	'Anaconda',
];

const images = {
	cat: ['cat-1.png', 'cat-2.png', 'cat-3.png', 'cat-4.png'],
	taylor: ['taylor-1.png', 'taylor-2.png', 'taylor-3.png', 'taylor-4.png'],
	photography: ['photo-1.png', 'photo-2.png', 'photo-3.png', 'photo-4.png'],
};

export { stacks, images };
